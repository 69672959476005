<script lang="ts" setup>
const { t } = useI18n();
</script>

<template>
  <div class="pulsedLogo">
    <NuxtImg
      src="/logo.svg"
      :alt="t('alt')"
      width="600"
      height="600"
      class="pulsedLogo__image origin-center block w-full h-full"
    />
  </div>
</template>

<i18n>
en:
  alt: Sex Play website logo

ru:
  alt: Логотип сайта Sex Play
</i18n>

<style lang="scss">
.pulsedLogo {
  width: 140px;
  height: 140px;

  @media (min-width: 640px) {
    width: 160px;
    height: 160px;
  }

  @media (min-width: 1600px) {
    width: 192px;
    height: 192px;
  }

  &__image {
    animation: animated-logo 2.5s infinite linear;
  }

  @keyframes animated-logo {
    0% {
      transform: scale(1);
    }

    50% {
      transform: scale(1.1);
    }

    100% {
      transform: scale(1);
    }
  }
}
</style>
